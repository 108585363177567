import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Table } from 'react-bootstrap';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesAscen: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Ascension info</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_ascension.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Ascension info</h1>
          <h2>
            Ascension information and guide - it will explain how the system
            works in AFK Journey.
          </h2>
          <p>
            Last updated: <strong>22/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Ascension" />
        <StaticImage
          src="../../../images/afk/generic/beginner_8.webp"
          alt="Guides"
        />
        <p>
          In AFK Journey the concept of fodder has been removed and the whole{' '}
          <strong>Ascension system has been simplified</strong>. Now, to ascend
          a character you either need to gather their dupes or faction-tied
          Acorns (which can be obtained from various shops or from pulling). The
          table below shows all the currently available tiers in the game and
          the requirements to reach them.
        </p>
        <h5>Ascension requirements</h5>
        <Table striped bordered responsive className="afk-ascension">
          <thead>
            <tr>
              <th>Current Tier</th>
              <th>Target Tier</th>
              <th>
                <AFKItem name="A-Level" />
              </th>
              <th>
                <AFKItem name="S-Level" />
              </th>
              <th>
                <StaticImage
                  src="../../../images/afk/icons/faction_cele.png"
                  alt="Celestial"
                  className="faction-icon"
                />{' '}
                <StaticImage
                  src="../../../images/afk/icons/faction_hypo.png"
                  alt="Hypogean"
                  className="faction-icon"
                />{' '}
                <span>Celestial / Hypogean</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tier elite">Elite</td>
              <td className="tier elite">Elite+</td>
              <td className="info">
                <div className="combined">
                  <strong>1x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">-</td>
              <td className="info">-</td>
            </tr>
            <tr>
              <td className="tier elite">Elite+</td>
              <td className="tier epic">Epic</td>
              <td className="info">
                <div className="combined">
                  <strong>3x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">-</td>
              <td className="info">-</td>
            </tr>
            <tr>
              <td className="tier epic">Epic</td>
              <td className="tier epic">Epic+</td>
              <td className="info">
                <div className="combined">
                  <strong>4x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>1x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>1x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier epic">Epic+</td>
              <td className="tier legendary">Legendary</td>
              <td className="info">
                <div className="combined">
                  <strong>5x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>50x</strong> <AFKItem name="Acorn" hideName />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>1x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier legendary">Legendary</td>
              <td className="tier legendary">Legendary+</td>
              <td className="info">
                <div className="combined">
                  <strong>8x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>1x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier legendary">Legendary+</td>
              <td className="tier mythic">Mythic</td>
              <td className="info">
                <div className="combined">
                  <strong>10x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>100x</strong> <AFKItem name="Acorn" hideName />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier mythic">Mythic</td>
              <td className="tier mythic">Mythic+</td>
              <td className="info">
                <div className="combined">
                  <strong>12x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier mythic">Mythic+</td>
              <td className="tier supreme">Supreme</td>
              <td className="info">
                <div className="combined">
                  <strong>200x</strong> <AFKItem name="Acorn" hideName />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>200x</strong> <AFKItem name="Acorn" hideName />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier supreme">Supreme</td>
              <td className="tier supreme">Supreme+</td>
              <td className="info">
                <div className="combined">
                  <strong>20x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>4x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="para-info" colSpan={5}>
                To upgrade your characters past{' '}
                <strong className="supreme">Supreme+</strong> you need to obtain
                25 characters of that tier. Also, you no longer get skill
                upgrade or unlocks past this point. Instead, only the stats are
                increased (2.5-3% per Paragon level).
              </td>
            </tr>
            <tr>
              <td className="tier supreme">Supreme+</td>
              <td className="tier paragon">Paragon 1</td>
              <td className="info">
                <div className="combined">
                  <strong>45x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>6x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>4x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier paragon">Paragon 1</td>
              <td className="tier paragon">Paragon 2</td>
              <td className="info">
                <div className="combined">
                  <strong>45x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>6x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>4x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier paragon">Paragon 2</td>
              <td className="tier paragon">Paragon 3</td>
              <td className="info">
                <div className="combined">
                  <strong>68x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>9x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>6x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier paragon">Paragon 3</td>
              <td className="tier paragon">Paragon 4</td>
              <td className="info">
                <div className="combined">
                  <strong>68x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>9x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>6x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <h5>Summary</h5>
        <ul>
          <li>
            To ascend an <AFKItem name="A-Level" /> character Supreme+ tier you
            will need the original copy + <strong>63 dupes</strong> (64 in
            total) and <strong>200 Faction Acorns</strong>,
          </li>
          <li>
            To ascend an <AFKItem name="S-Level" /> character to Supreme+ you
            will need the original copy + <strong>7 dupes</strong> (8 in total)
            and <strong>350 Faction Acorns</strong>.
          </li>
          <li>
            To ascend <AFKItem name="Celestial" /> and{' '}
            <AFKItem name="Hypogean" /> characters to Supreme+ you will need the
            original copy + <strong>13 dupes</strong> (14 in total).
          </li>
        </ul>
        <h5>Unlocks</h5>
        <ul>
          <li>
            A character needs to reach the{' '}
            <strong className="legendary">Legendary+</strong> tier to unlock{' '}
            <strong>Hero Focus</strong> that gives them bonus to one of their
            stats or gives a brand new effect,
          </li>
          <li>
            A character needs to reach the{' '}
            <strong className="mythic">Mythic+</strong> tier to unlock{' '}
            <strong>Exclusive Weapon</strong> which gives them a new passive
            skill (that can be upgraded up by leveling the EX Weapon),
          </li>
          <li>
            A character needs to reach the{' '}
            <strong className="supreme">Supreme+</strong> tier to unlock{' '}
            <strong>Skill Enhancement</strong> that give an additional effect to
            one of the their skills.
          </li>
        </ul>
        <h5>Paragon tiers</h5>
        <ul>
          <li>
            To upgrade your characters to{' '}
            <strong className="paragon">Paragon 1</strong> you need to obtain 25
            characters of <strong className="supreme">Supreme+</strong> tier.
          </li>
          <li>
            To upgrade your characters to{' '}
            <strong className="paragon">Paragon 2</strong> you need to obtain 20
            characters of <strong className="paragon">Paragon 1</strong> tier.
          </li>
          <li>
            To upgrade your characters to{' '}
            <strong className="paragon">Paragon 3</strong> you need to obtain 15
            characters of <strong className="paragon">Paragon 2</strong> tier.
          </li>
          <li>
            To upgrade your characters to{' '}
            <strong className="paragon">Paragon 4</strong> you need to obtain 15
            characters of <strong className="paragon">Paragon 3</strong> tier.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesAscen;

export const Head: React.FC = () => (
  <Seo
    title="Ascension info | AFK Journey | Prydwen Institute"
    description="Ascension information and guide - it will explain how the system works in AFK Journey."
    game="afk"
  />
);
